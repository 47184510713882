import { twMerge } from 'tailwind-merge';

import { ProductLinks, WizardSteps } from '@constants/index';

import DefaqtoLogo from '@images/defaqto.png';
import { Bell, HouseCross } from '@images/icons';
import insuranceProviders from '@images/InsuranceProviders.webp';

type SignpostProps = {
  subtitle: string;
  title: string;
  price?: number;
  text?: string;
  image?: JSX.Element;
  buttonText?: string;
  buttonLink?: string;
  onButtonClick?: (e: any) => any;
  newTab?: boolean;
  children?: React.ReactNode;
  fullWidth?: boolean;
  disableButton?: boolean;
  openCard?: WizardSteps;
  showBell?: boolean;
};

export const Signpost = ({
  subtitle,
  title,
  price,
  image,
  buttonText,
  buttonLink,
  onButtonClick,
  text,
  newTab,
  children,
  fullWidth,
  disableButton,
  openCard,
  showBell,
}: SignpostProps) => {
  return (
    <div
      className={twMerge(
        'md:max-w-auto mx-auto flex w-full flex-col gap-5 rounded-3xl',
        fullWidth ? 'max-w-full' : 'max-w-xl'
      )}
    >
      <div className="flex w-full items-start justify-between gap-3 md:gap-8">
        <div className="flex flex-col gap-2">
          <span className="font-bold">{subtitle}</span>
          <h4 className="text-2xl font-medium">
            {title}
            {!!price && (
              <>
                {' '}
                £{price}
                <span className="text-lg">.00</span>
              </>
            )}
          </h4>
        </div>
        {showBell ? (
          <Bell className="h-12 w-12" />
        ) : (
          <span className="flex h-12 w-12 min-w-12 items-center justify-center rounded-full bg-home">
            <HouseCross className="fill-white" height="28px" />
          </span>
        )}
      </div>
      {children}
      <div className="flex w-full flex-col items-center gap-8 md:items-start">
        {text && <p className="text-left text-base">{text}</p>}
        {image && <div className="flex w-full justify-center">{image}</div>}

        {!!buttonText && (
          <a
            className={twMerge(
              'font-base w-full cursor-pointer rounded-xl bg-primary px-5 py-3 text-center text-lg font-medium text-white sm:w-auto md:w-full',
              disableButton ? 'cursor-not-allowed bg-[#E2E2E2]' : 'hover:bg-primary-dark'
            )}
            {...(!!buttonLink && { href: buttonLink })}
            {...(!!openCard && {
              onClick: () => {
                const card = document.getElementById(`${openCard}-header`);
                card?.click();
              },
            })}
            {...(!!onButtonClick && { onClick: onButtonClick })}
            aria-disabled={disableButton}
            target={newTab ? '_blank' : ''}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        )}
      </div>
    </div>
  );
};

export const BuyerProtectionSignpost = () => (
  <Signpost
    subtitle="Protect your move"
    title="Home Buyers Protection from"
    price={74}
    text="Did you know you can cover your legal, survey and mortgage costs should your purchase fall through."
    buttonText="Protect your move fees"
    buttonLink={ProductLinks.BUYER_INSURANCE}
    newTab
  />
);

export const SellerProtectionSignpost = () => (
  <Signpost
    subtitle="Protect your move"
    title="Home Sellers Protection from"
    price={69}
    text="Did you know you can cover your legal costs should your sale fall through."
    buttonText="Protect your move fees"
    buttonLink={ProductLinks.SELLER_INSURANCE}
    newTab
  />
);

export const EnergySignpost = () => (
  <Signpost
    subtitle="Get move ready"
    title="Save on your energy bills"
    text="Home can help find the perfect tariff for your place in less than 10 minutes, and even makes switching a breeze."
    buttonText="View energy tariffs"
    buttonLink="/wizard/energy/"
    newTab
  />
);

export const BroadbandSignpost = () => (
  <Signpost
    subtitle="Get move ready"
    title="Set up broadband"
    text="Find the right broadband deal for you and get set up in less than 5 minutes."
    buttonText="View broadband deals"
    buttonLink="/wizard/broadband/"
    newTab
  />
);

export const ConveyancingSignpost = () => (
  <Signpost
    subtitle="To do"
    title="Instruct one of our trusted conveyancing partners"
    buttonText="Get free conveyancer quotes"
    buttonLink={ProductLinks.CONVEYANCING}
    newTab
  >
    <p className="font-light">
      Find the right conveyancer for your move with conveyancing packages designed for you. We shave
      an average of 60 days off the time it takes to complete.
    </p>
  </Signpost>
);

export const MortgageSignpost = ({ isDevEnvironment, isSubmitting, setIsSubmitting }: {
  isDevEnvironment: boolean,
  isSubmitting: boolean,
  setIsSubmitting: any
}) => (
  <Signpost
    subtitle="To do"
    title="Find the right mortgage deal for you"
    buttonText="Ask SPF to contact me"
    onButtonClick={(e: any) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      fetch('/api/home/spf/send-lead', { method: 'POST' })
        .then(res => e.target.innerText = 'SPF will be in touch with you soon!')
        .catch(err => e.target.innerText = 'That didn\'t work, try again later');
    }}
  >
    <p className="font-light">
      Home partners with SPF Private Clients, a leading UK mortgage broker who for over 25 years have been helping
      clients to turn their property dreams into reality. They are independent and can access the whole market, working
      with a wide number of lenders.
    </p>
  </Signpost>);

export const InsuranceSignpost = () => (
  <Signpost
    subtitle="To do"
    title="View quotes from over 50 UK insurance providers, including"
    image={<img src={insuranceProviders} alt="Insurance" className="max-w-full md:max-w-[400px]" />}
    buttonText="Get Home Insurance"
    buttonLink={ProductLinks.HOME_INSURANCE}
    newTab
  />
);

export const InsuranceReminderSignpost = () => (
  <Signpost
    subtitle="Don't forget"
    title="You need to have buildings insurance in place before exchanging"
    buttonText="Find the best insurance policy for you"
    buttonLink={ProductLinks.HOME_INSURANCE}
    newTab
  />
);

export const TenantsInsuranceSignpost = () => (
  <Signpost
    subtitle="To do"
    title="Get a quote for tenants insurance to protect your belongings"
    buttonText="Get a bespoke tenants insurance quote"
    buttonLink={ProductLinks.TENANTS_INSURANCE}
    newTab
  >
    <p>
      Make sure your belongings are protected with tenants insurance. Covering household goods,
      personal property, valuables and pedal cycles in your home.
    </p>
    <div className="flex w-full items-center justify-between gap-10">
      <span className="font-heading text-2xl font-medium">
        Speak to your tenants insurance team
      </span>
      <img src={DefaqtoLogo} alt="Defaqto" className="w-24" />
    </div>
  </Signpost>
);

export const RemovalsSignpost = ({ children }: { children: any }) => (
  <Signpost
    subtitle="To do"
    title="Book your removals"
    text="Home's got lorry loads of choice. Pick one of our reliable partners, and book them in. Easy."
  >
    {children}
  </Signpost>
);

export const ConciergeReminderSignpost = () => (
  <Signpost
    subtitle="Don't forget"
    title="We're on hand to help with anything you might need while moving and settling in"
    buttonText="Speak to your concierge"
    buttonLink="#home-team"
  />
);
